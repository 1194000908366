import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueGtm from "@gtm-support/vue2-gtm"
import VueCompositionAPI from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import payloadGTM from './libs/vue-gtm.js'
// 3rd party plugins
require('./libs')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Vue.use(VueGtm, payloadGTM )
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')
// import assets styles
require('@/assets/scss/style.scss')
Vue.config.productionTip = false
//timer de actividad de usuario idle-vue

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
}).$mount('#app')
