// import isAuthenticatedGuard from '../../auth/router/auth-guard'

export default [  
    {
        path: '/home',
        name: 'home',
        component: () => import('@/modules/shop/views/Home'),        
        meta: {            
            contentClass: 'ecommerce-application',            
            gtm: 'market',
            layout: 'full',
        },
    }, 
    {
        path: '/view-more',
        name: 'view-more',
        component: () => import('@/modules/shop/views/ViewMore'),        
        meta: {            
            contentClass: 'ecommerce-application',            
            gtm: 'market',
            layout: 'full',
        },
    },     
    {
        path: '/sales',
        name: 'sales',
        component: () => import('@/modules/shop/views/Experiencies'),        
        meta: {            
            gtm: 'market',
            layout: 'full',
        },
    },
    {
        path: '/sales/:slug',
        name: 'sales-product-details',
        component: () => import('@/modules/shop/views/DetailExperience'),
        meta: {
            // requiresAuth: true,
            gtm: 'productDetail',
            layout: 'full',
        },
        props: ( route ) => {
            return {
                slug: route.params.slug
            }
        },        
    },    
    {
        path: '/order',
        name: 'order-summary',
        component: () => import('@/modules/shop/views/OrderSummary'),   
        // beforeEnter: isAuthenticatedGuard,        
             
        meta: {
            // requiresAuth: true,
            
            gtm: 'checkout',
            layout: 'full',

        },
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('@/modules/shop/views/Checkout'),                
        meta: {
            // requiresAuth: true,
            contentClass: 'ecommerce-application',
            gtm: 'payment',
            layout: 'full',

        },
    },        
]