import { v4 as uuidv4 } from 'uuid'
import jwt from 'jsonwebtoken'
import store from '../store/index'
import { menuItemsCompleto, menuItemsSinEstancias, menuItemsDefault} from '../data/navigation'
import { find } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios' 
export const baseUrl = process.env.VUE_APP_BASE_URL_API

const handlerErrors = (errorMessage, errorResponse) => {
    let stringError = ''
    const responseStatus = errorResponse.status || false
    const responseStatusText = errorResponse.statusText || false
    const responseStatusDataDetail = errorResponse.data.detail || false

    stringError += '<strong>Error message:</strong> ' + errorMessage + '<br>'

    if (responseStatus) {
        stringError += '<strong>Error code:</strong> ' + responseStatus + '<br>'
    }

    if (responseStatusText) {
        stringError += '<strong>Status text:</strong> ' + responseStatusText + '<br>'
    }

    if (responseStatusDataDetail) {
        stringError += '<strong>Detail:</strong> ' + responseStatusDataDetail + '<br>'
    }

    return stringError
}

const currentDate = () => {
    let date = new Date();
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);
    // current month
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
    let year = date.getFullYear()

    return year + "-" + month + "-" + day
}

const formatDate = (fecha, locale) => {

    const date = new Date(fecha + 'T00:00:00')

    const mesesSpanish = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dia = date.getDate()
    const mes = date.getMonth()
    const yyy = date.getFullYear()

    const stringEs = dia + '  ' + mesesSpanish[mes] + ' ' + yyy
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}

const formatDateOnly = (fecha, locale) => {

    const date = new Date(fecha)
    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dia = date.getDate()
    const mes = ("0" + (date.getMonth() + 1)).slice(-2)
    const yyy = date.getFullYear()

    const stringEs = yyy + '-' + mes + '-' + dia
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}

const checkItemsCartSihotCode = (cart) => {
    let resultados = cart.filter(item => item.serviciosihot == null)
    if (resultados.length > 0) {
        store.commit('shop/setMakeRoomCharge', true, { root: true })
        store.commit('shop/setManualRoomChargeCliente', true, { root: true })
        return { ok: true, objetos: resultados }
    } else {
        store.commit('shop/setMakeRoomCharge', false, { root: true })
        store.commit('shop/setManualRoomChargeCliente', false, { root: true })
        return { ok: false, objetos: null }
    }

}


const formatBeoProductToSave = (detalles, id, infobeo) => {
    detalles.forEach((detalle) => {
        delete detalle.infobeo
        detalle.ordersdetail = id
        detalle.infobeo = infobeo
        detalle.departamento = detalle.departamentoid,
        delete detalle.departamentoid
        delete detalle.departamentoname
    })
    return detalles
}
const transfersIsValid = (detailOrder) => {
    let valiTranfers = detailOrder.filter(item => item.coupon != '')
    let resultados = valiTranfers.filter(item => item.itinerary.airline == '' || item.itinerary.pickup == '' || item.itinerary.flight == '')
    
    if (resultados.length > 0) {
        return true
    } else {
        return false
    }
}

const transfersIsValidNull = (detailOrder) => {
    let transfersIsValidNull = detailOrder
    let resultados = transfersIsValidNull.filter(item => item.itinerary.airline == '' || item.itinerary.pickup == '' || item.itinerary.flight == '' || item.itinerary.flighttime == '' || item.itinerary.ito == '' || item.itinerary.ifrom == '')    
    if (resultados.length > 0) {
        return true
    } else {
        return false
    }
}

const stringAleatorio = () => {    
    return uuidv4() 
}
const validUserLogin = () => {
    const isLoggedUser = store.state.auth.isLogged
    const hasSalesChannel = store.state.shop.canalventa
    const hasPointOfSales = store.state.shop.puntoventa
    if (isLoggedUser && hasSalesChannel && hasPointOfSales) {
        return true
    } else {
        return false
    }
}

const generateMenuList = ( seccionCanalUser ) => {         
    const hasVendorCode =  store.state.auth.user?.canalventa?.dingusvendorcode || false        
    if( seccionCanalUser != 0 ){        
        if( seccionCanalUser == 6 || seccionCanalUser == 29  ){ 
            return hasVendorCode ? menuItemsCompleto : menuItemsSinEstancias            
        } else {
            return menuItemsDefault          
        }  
    } else {
        return menuItemsDefault
    }    
}

const validateDataInCart = (cart) => {
    const itemFind = find(cart, { requiredFieldsFilled: false })
    if (itemFind) {
        Vue.$toast({
            component: ToastificationContent,
            props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: `💡 Existe por lo menos algún elemento sin completar sus datos, asegurese de completar esa información.`,
                variant: 'danger',
            },
        }, {
            position: 'bottom-right',
            timeout: 4000,
        })
        return true
    } else {
        return false
    }
}

const camposRequeridosPorLLenar = (product) => {
    let camposFaltantes = []    
    const { categoryName, itinerario, operationdate } = product
    if (categoryName == "Transfer") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
        if (!itinerario.ifrom || itinerario.ifrom == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Origen" })
        }
        if (!itinerario.ito || itinerario.ito == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Destino" })
        }
        if (!itinerario.pax || itinerario.pax == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Pax" })
        }
        if (!itinerario.trip || itinerario.trip == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Tipo de servicio" })
        }
    }

   

    if (categoryName != "Transfer") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
    }
   
    return camposFaltantes
}
const toDecimal = (stringToDecimal) => {
    return String((Math.round(stringToDecimal * 100) / 100).toFixed(2))
}

const toJson = (stringToJson) => {
    return JSON.parse(JSON.stringify(stringToJson))
}

const showAlertMessage = (title = 'Ok', icon = 'BellIcon', text, variant = 'success', timeout = '3000', position = 'bottom-right') => {
    Vue.$toast({
        component: ToastificationContent,
        props: { title, icon, text, variant },
    }, { timeout, position })
}



const consoleMsgFinally = (action, message) => {
    if (process.env.NODE_ENV == 'development' ) {
        console.log(`[${action}] ${message}`)
    }
}


const makeArrayCategories = ( categories ) => {

    let hotels = []
    let defaults = []
    let services = [
        { section:'Services', action: 'get-events',  text: 'Events', value: 'e634becc-7d3e-11ec-90d6-0242ac120003'  },
        { section:'Services', action: 'get-packages', text: 'Packages', value: 'f20a5f0e-7d3e-11ec-90d6-0242ac120003'  },
    ]

    categories.forEach( ( category ) => {
        const { section, id, name } = category 
        category.action = 'get-products-by-category'
        category.text = name
        category.value = id
        delete category.id
        delete category.name
        if( section == 'Servicios Hoteleros' ){
            hotels.push( category )
        }
        if( section == 'Categorías' ){
            defaults.push( category )
            defaults.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente
        } 
    })
    // const bestSeller = { section:'Categorías', action: 'get-products-best-seller',text: 'Best Seller', value: 'BestSeller-01'}
    // defaults.unshift( bestSeller )
    return { hotels, defaults, services }
}

const formatNestedCategories = (categories) =>{
    let hotels = []
    let withSubCategories = []
    let onlyCategories = []    
    let subCategories = []    

    let services = [
        { section:'Services', action: 'get-events',  text: 'Events', value: 'e634becc-7d3e-11ec-90d6-0242ac120003'  },
        { section:'Services', action: 'get-packages', text: 'Packages', value: 'f20a5f0e-7d3e-11ec-90d6-0242ac120003'  },
    ]

    categories.forEach( ( category ) => {
        const { id, name } = category 
        category.text = name
        category.value = id
        category.coverImage = null
        delete category.id
        delete category.name
        if(category.subcategory.length){    
            category.action = 'get-products-by-sub-category'
            category.uuid = stringAleatorio()
            withSubCategories.push( category )
            withSubCategories.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente            
            category.subcategory.forEach( subCat => {
                subCat.uuid = stringAleatorio()
                subCat.idCategory = category.value 
                subCat.nameCategory = category.text 
                subCategories.push(subCat)
            })
        } 
        if(!category.subcategory.length){
            category.action = 'get-products-by-category'            
            onlyCategories.push( category )
            onlyCategories.sort((a, b) => a.text > b.text ? 1 : -1) //ordeno alfabeticamente                                   
        }      
    })

    return { hotels, withSubCategories, onlyCategories, subCategories, services }
}

const formatItems = ( products, type ) => {
    if( type === 'products'){        
        return formatListProducts( products ) 
    }
    if( type === 'events'){        
        return formatListEvents( products ) 
    } 
    if( type === 'packages'){        
        return formatListPackage( products ) 
    }        
}

const formatListProductsLanding = ( products, valueCategory ) => {
    let items = []
    products.forEach((product ) => {        
        product.modelType = 'product'
        product.valueFromCategory = valueCategory
        product.categoryName = product.category.name || ''
        
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.type
        delete product.category
        delete product.productinfo
        delete product.detailId 
        delete product.productavailability 
        delete product.rulesProduct 
        delete product.supplier 
        delete product.supplierPromo 
        delete product.usabeo 
        if( product.detail.length ){  
            for (let index = 0; index < product.detail.length; index++) {                
                items.push({
                    ...product,
                    detailSelected: product.detail[index]
                })                 
            }          
        }                             
    })
    items.forEach( item => {
        delete item.detail         
    });
    return items
}

const formatListProducts = ( products, valueCategory ) => {
    let items = []
    products.forEach((product ) => {        
        product.modelType = 'product'
        product.valueFromCategory = valueCategory
        product.categoryName = product.category.name || ''        
        product.total = '0.00'
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.type
        delete product.category
        delete product.productinfo
        delete product.detailId 
        delete product.productavailability 
        delete product.rulesProduct 
        delete product.supplier 
        delete product.supplierPromo 
        delete product.usabeo 
        if( product.detail.length ){
            product.detail.forEach( detail => {
                detail.qty = 0
                detail.subTotal = 0
                delete detail.seccionventaid 
            })
            items.push(product) 
        }     
    })

    return items
}


const formatListPackage = ( packages, valorCategory ) => {
    packages.forEach((product) => {
        product.modelType = 'package' 
        product.valueFromCategory = valorCategory
        product.detailSelected = {
            descmax: 0,
            detaildisplay: product.name,
            extrapax: false,
            id: product.id,
            saleprice: product.saleprice,
            servicesihot: product.servicesihot
        }
        delete product.products
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.category
        delete product.productinfo
    })

    return packages
}

const formatListEvents = ( events, valueCategory ) => {    
    let items = []
    events.forEach((product ) => {        
        const timeInitFormat = product.startTime.slice(0, -3)
        const timeEndFormat = product.endTime.slice(0, -3)
        const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        product.modelType = 'event'
        product.categoryName = 'Events'  
        product.valueFromCategory = valueCategory
        product.isSelected = false
        product.uuid = `event-${product.id}`                               
        const detalleIngles = product.eventdetail[1]
        product.detailSelected = {
            descmax: detalleIngles ? parseInt(detalleIngles.descmax) : 0,
            detaildisplay: product.name,
            id: detalleIngles ? detalleIngles.id : product.id,
            saleprice: product.saleprice,
            
        }
        product.startDateFormat = formatDate(product.startDate, 'en')
        product.endDateFormat = formatDate(product.endDate, 'en')
        product.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
        product.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
        product.currencyCode = product.currency.code
        product.hotelName = product.hotel.name
        product.locationName = product.location.name
        product.description = detalleIngles ? detalleIngles.description : ''
        delete product.eventdetail
        delete product.gallery
        delete product.currency
        delete product.hotel
        delete product.location
        delete product.category
        delete product.productinfo
        if(product.detailSelected  ){
            items.push(product) 
        } 
    })

    return items
}

const setParamsGetProducts = ( parametros, idseccanal ) => {
    const { type, language }  = parametros        
    if( language && type === 'products'){
        return { language, idseccanal }
    }else {
        if(type === 'products'){
           return { bs: 1, idseccanal } 
        }
        if(type !== 'products'){
           return { idseccanal }
        }
    }
}

const formatItemProduct = (item, type) => {    
    if ( type == 'packages') {
        return formatPackage(item)            
    }
    if ( type == 'products') {
        return formatProduct(item)            
    }

    if ( type == 'events') {
        return formatEvent(item)
    }
}
const formatPackage = (item) => {
    item.categoryName = 'Packages'
    item.modelType = 'package'    
    item.detaildisplay = item.name
    if (item.gallery) {
        item.gallery.forEach((item) => {
            item.key = stringAleatorio()
        })
    }
    if (item.products) {
        item.products.forEach((item) => {
            item.keyItem = stringAleatorio()
        })
    }
    item.detailSelected = {
        cost: item.cost || 0,
        descmax: 0,
        detailDesc: item.name,
        detaildisplay: item.name,
        
        id: item.id,
        saleprice: item.saleprice, 

    }
    return item
}

const formatProduct = (item) => {
    item.categoryName = 'Products'
    item.modelType = 'product'
    item.total = '0.00'
    
    if (item.gallery) {
        item.gallery.forEach((item) => {
            item.key = stringAleatorio()
        })
    }
    if (item.products) {
        item.products.forEach((item) => {
            item.keyItem = stringAleatorio()
        })
    }
    if( item.detail.length ){
        item.detail.forEach( detail => {
            detail.qty = 0
            detail.subTotal = 0
            delete detail.seccionventaid 
        })        
    }

    delete item.gallery
    delete item.videolink
    delete item.weblink
    delete item.type
    delete item.category
    delete item.productinfo
    delete item.detailId 
    delete item.productavailability 
    delete item.rulesProduct 
    delete item.supplier 
    delete item.supplierPromo 
    delete item.usabeo 

    return item
}

const formatEvent = event => {
    delete event.currency
    event.categoryName = 'Events'
    event.modelType = 'event'
    event.hotelName = event.hotel.name
    event.locationName = event.hotel.name
    event.description = event.name
    event.detailDesc = event.name
    event.detaildisplay = event.name
    const timeInitFormat = event.startTime.slice(0, -3)
    const timeEndFormat = event.endTime.slice(0, -3)
    const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
    const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
    event.startDateFormat = formatDate(event.startDate, 'en')
    event.endDateFormat = formatDate(event.endDate, 'en')
    event.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
    event.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
    event.detailSelected = {
        cost: event.cost || 0,
        descmax: 0,
        detailDesc: event.name,
        detaildisplay: event.name,
        id: event.id,
        saleprice: event.saleprice,   
        
    }    
    delete event.endDate
    delete event.endTime
    delete event.startDate
    delete event.startTime
    delete event.location

    return event
}

const overWriteAxiosHeaders = (queryParams) =>{
    //sobreescribo los datos en axios, una vez obtenido un token
    axios.defaults.headers.common['Authorization'] = 'Token '+ queryParams?.token
    axios.defaults.baseURL = baseUrl
    Vue.use(VueAxios, axios) // end    
}

const decodeJwt = ( stringJwt ) => {
    try {
        const decoded = jwt.verify(stringJwt, process.env.VUE_APP_JWT_SECRET )
        return {ok: true, ...decoded }
    } catch (error) {
        return { ok: false, msg: `${error.name}: ${error.message} ` }
    }
}
const makePayloadRequestProducts = items => {
    let requests = []
    items.forEach(item => {        
        const { type, product, detail } = item
        requests.push({idProducto: parseInt(product), idProductDetail:parseInt(detail), type, endPoint:type, language: null})
    })
    return requests
}

const generateProductsToAddCart = (itemsQueryParams, productsState) => {    
    let listCart = []
    itemsQueryParams.forEach( productItem  => {
        const { detail: idDetail, product } = productItem // propiedades de los items en queryParamsState  
        const productState = productsState.find( p => p.id === product ) //ubico el producto en el state de Products                 
        
        if(productState){
            const { detail, modelType } = productState
    
            if( modelType !== 'product' ){
                delete productState.detail
                delete productState.detailSelected.currency
                listCart.push(productState)
            }
            if( modelType === 'product' ){                
              const itemDetail = detail.find( detalle => detalle.id == idDetail)                     
              if( itemDetail ){ // si existe detalle, lo tomo y armo sus propiedades
                const { descmax, detaildisplay, extrapax, id, saleprice } = itemDetail
                productState.descmax = parseInt(descmax) || 0
                productState.detaildisplay = detaildisplay   
                productState.extrapax = extrapax
                productState.detailId = id
                productState.saleprice = saleprice
                delete productState.detail
                delete productState.detailSelected.currency
                listCart.push(productState)
              }            
            }
        }              
    })
    return listCart
}
export {
    validUserLogin,
    generateMenuList,
    handlerErrors,
    currentDate,
    formatDate,
    formatDateOnly,
    validateDataInCart,    
    formatBeoProductToSave,
    transfersIsValid,
    transfersIsValidNull,
    checkItemsCartSihotCode,
    stringAleatorio,
    camposRequeridosPorLLenar,
    toDecimal,
    toJson,
    showAlertMessage,
    consoleMsgFinally,    
    makeArrayCategories,
    formatListProducts,
    formatListProductsLanding,
    formatListPackage,
    formatListEvents,
    formatItems,
    setParamsGetProducts,
    formatItemProduct,
    overWriteAxiosHeaders,   
    decodeJwt,
    makePayloadRequestProducts,
    generateProductsToAddCart,
    formatNestedCategories
}