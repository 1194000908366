export function setLoadingProducts( state, bool) {
    state.isLoadingProducts = bool
}
export function setProducts( state, products) {
    state.products = products
}

export function setRelatedProducts( state, relatedProducts) {
    state.relatedProducts = relatedProducts
}

export function setBestSellersProducts( state, bestSellers) {
    state.bestSellers = bestSellers
}

// para ocupar en el buscador, filter
export function setFilter(state, data){
    state.filter.query = data
}
export function setProductDetail(state, product) {
    state.productDetail = product
}

//un producto al seleccionar su detalle, actualizo su propiedad detailSelected
export function setDetailSelectedInProduct(state, product) {
    let prod = state.products.find( p => p.id === product.id )
    prod.detailSelected = product.detailSelected
}

export function setSortBy( state, option) {
    state.orderBy = option.value
}

export function setQtyProductDetail( state, payload ) {
    let sum = 0
    const { idProduct, idDetail, qty, isBestSeller } = payload
    let product = null
    if( isBestSeller ){
        product = state.bestSellers.find( p => p.id === idProduct ) // busco el producto a mutar su detalle
    } 
    if( !isBestSeller){
        product = state.products.find( p => p.id === idProduct ) // busco el producto a mutar su detalle
    }
    const idx = product.detail.findIndex( detail => detail.id === idDetail  ) // busco el detalle por el idDetail
    product.detail[idx].qty = qty //muto su cantidad
    product.detail[idx].subTotal = qty * product.detail[idx].saleprice //genero subTotal
    product.detail.forEach(( detalle ) => {
        sum += parseFloat(detalle.subTotal)
    })
    product.total = String((Math.round(sum * 100) / 100).toFixed(2)) //decimal
}

export function setQtySingleProductDetail( state, payload ) { //usado en producto detalle, single product
    let sum = 0
    const { idDetail, qty } = payload
    let product = state.productDetail
    const idx = product.detail.findIndex( detail => detail.id === idDetail  ) // busco el detalle por el idDetail
    product.detail[idx].qty = qty //muto su cantidad
    product.detail[idx].subTotal = qty * product.detail[idx].saleprice //genero subTotal
    product.detail.forEach(( detalle ) => {
        sum += parseFloat(detalle.subTotal)
    })
    product.total = String((Math.round(sum * 100) / 100).toFixed(2)) //decimal
}


export function resetQtyProductDetail( state, payload ) {
    const { idProduct, isBestSeller } = payload
    let product = null
    if( isBestSeller ){
        product = state.bestSellers.find( p => p.id === idProduct ) // busco el producto a mutar su detalle
    } 
    if( !isBestSeller){
        product = state.products.find( p => p.id === idProduct ) // busco el producto a mutar su detalle
    }
    product.total = '0.00'    
    product.detail.forEach(( detalle ) => {
        detalle.qty = 0         
        detalle.subTotal = 0 
    })
}

export function resetQtySingleProductDetail( state ) { //usado en producto detalle, single product        
    let product = state.productDetail
    product.total = '0.00'    
    product.detail.forEach(( detalle ) => {
        detalle.qty = 0         
        detalle.subTotal = 0 
    }) 
}