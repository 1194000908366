import Vue from 'vue'
import store from '@/store' //para acceder a state
import { formatItemProduct, formatListProducts, formatListPackage, formatListEvents, currentDate, makeArrayCategories } from '@/helpers/helpers'
import {  fetchCurrencies, fetchPaymentMethods } from '@/modules/start/store/actions'


export async function getInitialContent(context, params){
    context.commit('setLoadingProducts', true) //set State loader  
    const content = await Promise.all([        
        fetchCurrencies(), fetchProducts( params ), fetchProductDetail(context, params), fetchPaymentMethods()
    ])                            
    const [ currencies, products, product, paymentMethods ] = content   
    const { creditCard } = paymentMethods  
    context.commit('start/setCurrencies', currencies, { root: true }) // set State
    context.commit('setProducts', products )   // set State
    context.commit('shop/setPaymentMethodClient', creditCard.id, { root: true } )   // set State
    context.commit('setLoadingProducts', false)  // set State loader  
    return { currencies, products, product, paymentMethods }
}

export async function getInitialContentLanding(context){
    const content = await Promise.all([        
        fetchBestSellersHome(), fetchCategoriesHome()
    ])                            
    const [ products, categories ] = content   
 
    return { products, categories }
}

export async function getInitialProducts(context, requestArray) {
    let items = []
    let requests = requestArray.map( payload => getProduct(payload) ) //generate array of promises to make requests
    const products = await Promise.all(requests) // return array of products as a result
    products.forEach(product => {
        if(product){
            items.push(product)
        }
    });
    return items
}


export async function fetchBestSellerProducts() {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: { bs: 1, idseccanal, language: 1}
        })        
        const payload = response && response.data                
        return  formatListProducts( payload, 'BestSeller-01' )

    } catch (error) {
        console.log( error )
    } finally {    
        console.log('fetchBestSellerProducts end')
    }
}



//peticiones para obtener lista de productos por categorias
export async function fetchProductsByCategories(context, category) {
    const { value } = category
    const idseccanal =  store.state.auth.user?.sectionCanal?.id       
    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: { idcat: value, idseccanal }
        })
        const payload = response && response.data   
           
        return formatListProducts( payload, value ) 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchProductsByCategories end')

    }
}

//peticiones para obtener lista de productos por categorias
export async function fetchProductsBySubCategory(context, subCategory) {
    const { idSubCategory, value } = subCategory
    const idseccanal =  store.state.auth.user?.sectionCanal?.id       
    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: { idsub: idSubCategory, idseccanal }
        })
        const payload = response && response.data   
           
        return formatListProducts( payload, value ) 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchProductsBySubCategory end')

    }
}

export async function fetchPackages(context) {    
    const idseccanal =  store.state.auth.user?.sectionCanal?.id       
    try {        
        const response = await Vue.axios({
            url: `/packages/`,
            params: { idseccanal }
        })
        const payload = response && response.data                      
        return formatListPackage( payload, 'f20a5f0e-7d3e-11ec-90d6-0242ac120003') 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchPackages end')

    }
}


export async function fetchEvents(context) {    
    const idseccanal =  store.state.auth.user?.sectionCanal?.id       
    try {        
        const response = await Vue.axios({
            url: `/events/`,
            params: { idseccanal }
        })
        const payload = response && response.data            

        const todayDate = currentDate()
        const fecha = new Date(todayDate)
        //se filtra los eventos que sean iguales o mayores a la fecha actual
        const result = payload.filter(item => new Date(item.endDate) >= fecha)             
        return formatListEvents( result, 'e634becc-7d3e-11ec-90d6-0242ac120003' ) 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchEvents end')

    }
}

export async function fetchProductDetail(context, payload) {

    const { idProducto, endPoint, language } = payload       
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    try {
        const response = await Vue.axios({
            url: `/${endPoint}/`,
            params: { id: idProducto, idseccanal, language}
        })
        const payload = response && response.data             
        if(payload.length > 0 ){            
            const item = formatItemProduct(payload[0], 'products') 
            delete item.type
            delete item.category
            delete item.active
            delete item.eventdetail
            delete item.productinfo
            delete item.products
            delete item.gallery
            
            return item 
        } else {
            return null
        }
    } catch (error) {
        console.log( error )        
    } finally {
        console.log('fetchProductDetail end')
    }
}
export async function getProduct(payload) {
    const { idProducto, endPoint } = payload    
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    const endPointRequest = endPoint === 'productswebsites' ? 'products' : endPoint
    try {
        const response = await Vue.axios({
            url: `/${endPointRequest}/`,
            params: { id: idProducto, idseccanal }
        })
        const payload = response && response.data        
        if(payload.length > 0){            
            const item = formatItemProduct(payload[0], endPointRequest) 
            delete item.type
            delete item.category
            delete item.active
            delete item.eventdetail
            delete item.productinfo
            delete item.products
            delete item.gallery        
            return item 
        }
    } catch (error) {
        console.log( error )        
    }
}

