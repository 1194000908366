export default {
    filter: {
        query: '',
    },
    isLoadingProducts: false,  
    orderBy: 'alfabetico',
    productDetail: null,       
    products: [],
    relatedProducts: [],
    bestSellers: [],
    
}