//start module state Hotels
export function setHotels(state, hotels) {
    state.hotels = hotels
}
//start module state Categories
export function setCategories(state, categories) {
    const { hotels, withSubCategories, onlyCategories, subCategories, services } = categories
    state.categories.hotels = hotels
    state.categories.withSubCategories = withSubCategories
    state.categories.onlyCategories = onlyCategories
    state.categories.subCategories = subCategories
    state.categories.services = services
}


export function setSelectedCategory(state, category ){
    state.selectedCategory = category
} 
 
//start module state Currencies
export function setCurrencies(state, currencies) {
    state.currencies = currencies
}

export function setLanguages(state, languages){
    state.languages = languages   
}









