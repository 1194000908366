export default {
    categories:{
        hotels: [],
        withSubCategories: [],
        onlyCategories:[], 
        subCategories:[], 
        services: [],
    },    
    currencies:[],      
    hotels:[],      
    languages:[],        
    selectedCategory: null
}