import { showAlertMessage } from '@/helpers/helpers'

export function setCurrencyCustomer(state, currency) {
    state.customerCurrency = currency
}

export function setCategorySelected(state, category) {
    state.categorySelected = category
}

export function addProductInCart(state, product) {
    state.cart = [...state.cart, product ]
    calculateTotalBreakdown(state)
    showAlertMessage( 'Added product', 'CheckCircleIcon', `🎉🎉 Product has been added: ${product.detaildisplay}.`, 'success', 5000, 'top-right')
}

export function removeProductInCart(state, uuid ) {
    state.cart = state.cart.filter( item => item.uuid !== uuid )
    calculateTotalBreakdown(state)
}

export function setBlackoutsDateProduct(state, payload) {
    const { uuid, blackoutDates , maxDate  } =  payload
    const idx = state.cart.findIndex( item => item.uuid === uuid ) 
    state.cart[idx].blackoutDates.disable = blackoutDates    
    state.cart[idx].blackoutDates.maxDate = maxDate    
}

export function setOperationDateProduct(state, payload) {
    const { uuid, date } =  payload
    const idx = state.cart.findIndex( item => item.uuid === uuid ) 
    state.cart[idx].operationdate = date 
    setIfRequiredFieldsFilled(state)
}

export function calculateTotalBreakdown(state) {
    let sum = 0
    const decimalDiscount = parseFloat(state.breakdown.discountApplied) / 100
    state.cart.forEach((product) => {
        const precio = (product.discountApplied > 0 ) ? product.salepriceWithDiscount : product.saleprice
        sum += (parseFloat(precio) * parseFloat(product.qty))
    })
    const descuento = sum * decimalDiscount
    state.breakdown.total = ((Math.round(sum * 100) / 100).toFixed(2)) - descuento
}

export function setIfRequiredFieldsFilled(state) {
    state.cart.forEach((item) => {
        item.requiredFieldsFilled = false
        if (item.categoryName == "Transfer") {
            if (
                item.operationdate && item.operationdate != '' &&
                item.typeDiscount != 'Incluido en el paquete' &&
                item.itinerario.ifrom && item.itinerario.ifrom != '' &&
                item.itinerario.ito && item.itinerario.ito != '' &&
                item.itinerario.pax && item.itinerario.pax != '' &&
                item.itinerario.trip && item.itinerario.trip != ''
            ) {
                item.requiredFieldsFilled = true
            }

            if (item.extrapax != false) {
                item.requiredFieldsFilled = true
            }
        }

        if (item.categoryName == "Flights") {
            if (
                item.operationdate && item.operationdate != ''

            ) {
                item.requiredFieldsFilled = true
            }
        }
        if (item.categoryName == "Reservas") {
            if (
                item.NamePrefix && item.NamePrefix != '' &&
                item.GivenName && item.GivenName != '' &&
                item.Surname && item.Surname != '' &&
                item.PhoneNumber && item.PhoneNumber != '' &&
                item.Email && item.Email != '' &&
                item.term && item.term != null &&
                !item.requiresAuthorization

            ) {
                item.requiredFieldsFilled = true
            }
        }
        if (item.categoryName != "Transfer" && item.categoryName != "Flights" && item.categoryName != "Reservas") {
            if (item.operationdate && item.operationdate != '' && item.typeDiscount != 'Incluido en el paquete') {
                item.requiredFieldsFilled = true
            }
        }
        if (item.typeDiscount == 'Incluido en el paquete' && item.isIncludenIn && item.operationdate) {
            item.requiredFieldsFilled = true            
        }
    })
}


export function setLoadingProcess(state, bool) {
    state.isLoadingProcess = bool
}

//seteo del canal de venta
export function setCanalVenta(state, canalVenta) {
    state.canalventa = canalVenta    
}

//seteo del punto de venta
export function setPuntoDeVenta(state, puntoDeVenta) {
    state.puntoventa = puntoDeVenta
}
//limpio el punto de venta
export function unsetPuntoDeVenta(state) {
    state.puntoventa = null
}
//pongo el currency default para el client
export function setCurrency(state, currency) {
    state.customer.currency = currency
}


export function setNoteItem(state, data) {
    const { uuid, notaInterna, notaCliente } = data
    let item = state.cart.find(item => item.uuid === uuid )
    item.note = notaInterna
    item.clientnote = notaCliente
    setIfRequiredFieldsFilled(state)
}

//para guardar el nombre del cliente del cart
export function setInfoClient(state, clientData) {
    state.customer.name = clientData.name.trim()
    state.customer.lastname = clientData.lastname.trim()
    state.customer.email = clientData.email.trim()
    state.customer.phone = clientData.phone.trim()
    state.customer.idUser = clientData.idUser
    state.customer.customertype = clientData.customertype
    state.customer.paymentmethod = clientData.paymentmethod
    state.customer.sihotCode = clientData.sihotCode

}

//reemplazar datos de cliente encontrado en consulta por email
export function replaceCustomerData(state, data) {
    const { email, lastname, name, phone, id } = data
    state.customer.email = email.trim()
    state.customer.lastname = lastname.trim()
    state.customer.name = name.trim()
    state.customer.phone = phone.trim()               
    state.customer.idCustomer = id        
}

//set id cliente
export function setIdCustomer(state, idCustomer) {            
    state.customer.idCustomer = idCustomer        
}

//quitar el id del cliente
export function removeIdCustomer(state) {            
    state.customer.idCustomer = null        
}

//para poner valor en la propiedad orderCreated del estado
export function setOrderCreated(state, order) {
    state.orderCreated = order
}

export function setStatusPaymentOrder(state, status) {
    state.orderCreated.statusPayment = status
}

export function setUrlBankPaymentOrder(state, url) {
    state.orderCreated.payment.url = url
}

//limpieza del carrito al terminar la venta
export function resetCartCustomerBreakdownOrder(state) {
    //limpio cart
    state.cart = []
    //reseteo datos del cliente
    state.customer.name = ''
    state.customer.lastname = ''
    state.customer.email = ''
    state.customer.phone = ''
    state.customer.idCustomer = null
    state.customer.sihotCode = null
    //reinicio el desgloce de totales
    state.breakdown.total = 0.00
    state.breakdown.discountApplied = 0   
    //borro la orden creada 
    state.orderCreated = null    
    //reset currency customer
    // state.customerCurrency = null    

}